import { Injectable, Injector } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { AuthService } from "@mt-ng2/auth-module";

@Injectable()
export class IsAdminLoadGuard  {
    public authService: AuthService;

    constructor(injector: Injector, private router: Router) {
        this.authService = injector.get<AuthService>(AuthService);
    }

    canLoad(): boolean | UrlTree {
        return this.isAdmin();
    }

    canActivate(): boolean | UrlTree {
        return this.isAdmin();
    }

    private isAdmin(): boolean | UrlTree {
        const isProvider = this.authService.currentUser.getValue().CustomOptions.IsProvider;
        if (isProvider) {
            return this.router.parseUrl('/provider-portal');
        } else {
            return true;
        }
    }
}
