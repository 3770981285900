import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IEncounterSignature } from '@model/interfaces/encounter-signature';
import { Observable } from 'rxjs';
import { IRequestSignatureDto } from '@model/interfaces/custom/request-signature.dto';

export const emptyEncounterSignature: IEncounterSignature = {
    DateCreated: new Date(),
    EncounterId: 0,
    Id: 0,
    Signature: '',
    ResetKey: 0x00,
    ResetKeyExpirationUtc: new Date(),
};

@Injectable({
    providedIn: 'root',
})
export class EncounterSignatureService extends BaseService<IEncounterSignature> {
    constructor(public http: HttpClient) {
        super('/encounter-signatures', http, [], { entityName: 'Encounter Signature' });
    }

    getEmptyEncounterSignature(): IEncounterSignature {
        return { ...emptyEncounterSignature };
    }

    sendRequestEmail(encounterId: number): Observable<void> {
        return this.http.post<void>(`/encounter-signatures/send-email/${encounterId}`, {});
    }

    getRequestSignatureData(encounterId: number, resetKey: string): Observable<IRequestSignatureDto> {
        return this.http.post<IRequestSignatureDto>(`/encounter-signatures/data`, { EncounterId: encounterId, ResetKey: resetKey });
    }

    addSignature(encounterId: number, formData: FormData): Observable<void> {
        return this.http.post<void>(`/encounter-signatures/sign/${encounterId}`, formData);
    }
}
