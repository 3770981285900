import { Component, ElementRef, OnInit, ProviderToken, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environment';
import { IRequestSignatureDto } from '@model/interfaces/custom/request-signature.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { EncounterSignatureService } from '@provider/calendar/calendar-encounter/encounter-signatures/services/encounter-signature.service';
import * as SignaturePad from 'signature_pad';

@Component({
    selector: 'app-request-signature',
    templateUrl: './request-signature.component.html',
    styleUrls: ['./request-signature.component.less'],
})
export class RequestSignatureComponent implements OnInit {
    logoFull = `${environment.assetsPath }logo-full.png`;
    private _route = inject(ActivatedRoute);
    private _encounterSignatureService = inject(EncounterSignatureService as ProviderToken<EncounterSignatureService>);
    private _notificationsService = inject(NotificationsService);
    private el = inject(ElementRef);

    resetKey: string;
    encounterId: number;
    data: IRequestSignatureDto;
    hasSignature: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signaturePad: any;
    canvas: HTMLCanvasElement;

    get isNotSigned(): boolean {
        return this.signaturePad && this.signaturePad.isEmpty();
    }

    ngOnInit(): void {
        this._route.queryParams.subscribe((params) => {
            this.resetKey = params.resetKey;
            this.encounterId = +params.encounterId;
        });
        this._encounterSignatureService.getRequestSignatureData(this.encounterId, this.resetKey).subscribe((res) => {
            this.data = res;
            this.hasSignature = this.data.HasSignature;
            this.canvas = this.el.nativeElement.querySelector('canvas');
            this.signaturePad = new SignaturePad.default(this.canvas);
        });
    }

    clearSignaturePad(): void {
        this.signaturePad.clear();
    }

    addSignature(): void {
        const formData: FormData = new FormData();
        const byteString = atob(this.signaturePad.toDataURL().split(',')[1] as string);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const signatureBlob = new Blob([ab]);
        formData.append("Signature", signatureBlob);

        this._encounterSignatureService.addSignature(this.encounterId, formData).subscribe(() => {
            this._notificationsService.success("Signature has been successfully added.");
            this.hasSignature = true;
        });
    }
}
